import * as React from 'react'
import styled from "styled-components"
import Layout from "../components/Layout";
import {Meta} from "../components/Meta";
import {theme} from "../components/theme";
import {Helmet} from "react-helmet";
import {URL_NAME} from "../config/constans";

interface ErrorPageProps {
}

const ErrorPage: React.FC<ErrorPageProps> = ({}) => {
    return (
        <Layout>
            <Meta/>
            <Helmet>
                <title>{`mogFlowts - 404`}</title>
            </Helmet>
            <>

                <Container>
                    <h1>Page not found!!</h1>
                </Container>
            </>
        </Layout>
    )
};

export default ErrorPage

const Container = styled.div`
  margin: 0 auto;
  padding: 40px 15px 80px;

  width: ${theme.width.main};
  
  text-align: center;
`;
